import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { Article, ArticleHeader } from "./layout"
import Img from "gatsby-image"
import sizeMe from "react-sizeme"
import StackGrid from "react-stack-grid"
import Carousel, { Modal, ModalGateway } from "react-images"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClone, faExpand } from "@fortawesome/free-solid-svg-icons"

const GalleryImage = styled.div`
  cursor: pointer;
  &:hover {
    > div {
      > .multiImage {
        color: #fff;
        visibility:visible;
      }

    }
  &:active {
    > div {
      > .multiImage {
        color: #aaa;
        visibility:visible;
      }
    }
  }
`
const FooterText = styled.div`
  width: 100%;
  font-family: "MomsTypewriter", "Josefin Slab", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  > p {
    text-align: center;
    color: #fff;
    font-size: 16px;
    @media screen and (max-width: 750px) {
      font-size: 12px;
    }
  }
`
const Header = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  opacity: interactionIsIdle ? 0 : 1;
  padding-bottom: 20;
  position: absolute;  
  transition: opacity 300ms transform 300ms;
  font-family: "MomsTypewriter","Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  > p {
    margin-left:10px;
    color: #fff;
    width:100%;
    text-align:center;
    text-transform: uppercase;
    font-size: 1.25em;
    @media screen and (max-width: 500px){
      // font-size: 14px;
      text-align: center;
      // padding-right: 10%;
      margin-top: 5px;
    }
  }
`
const ModalButton = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
  outline: 0;
  position: absolute;
  cursor: pointer;
  width: 45px;
  height: 45px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  transition-property: background-color, box-shadow;
  transition: all 0.5s ease;
  :hover {
    background-color: #6bf1d8;
    border: 1px solid #888;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.9);
  }
`
const CloseButton = styled(ModalButton)`
  width: 30px;
  height: 30px;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  @media screen and (max-width: 750px) {
    // width:25px;
    // height:25px;
    // margin-right:5px;
    // margin-top:5px;
    // top:0;
    display: none;
  }
`
const LeftButton = styled(ModalButton)`
  top: 48%;
  transition: background-color 200ms;
  left: 0;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
`
const RightButton = styled(ModalButton)`
  top: 48%;
  transition: background-color 200ms;
  right: 0;
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
`

const CustomFooter = ({ views, currentIndex }) => {
  const desc = views[currentIndex].desc
  return (
    <FooterText>
      <p>{desc}</p>
    </FooterText>
  )
}

const CustomPrev = props => {
  return <LeftButton onClick={props.innerProps.onClick}>&lt;</LeftButton>
}

const CustomNext = props => {
  return <RightButton onClick={props.innerProps.onClick}>&gt;</RightButton>
}

const CustomHeader = ({ views, currentIndex, modalProps }) => {
  const caption = views[currentIndex].caption
  const { onClose } = modalProps
  return (
    <Header>
      <p>{caption}</p>
      <CloseButton onClick={onClose}>X</CloseButton>
    </Header>
  )
}
const CustomView = ({ data }) => (
  <div className="react-images__view">
    <img
      className="react-images__view-image"
      src={data.src}
      srcSet={data.srcSet}
      alt={data.caption}
    />
  </div>
)
// const ImageOrVid = props => {
//   const { data } = props;

//   return (
//     <div className={styles.view}>
//       {data.videoUrl ? (
//         <video width="320" height="240" controls>
//             <source src="movie.mp4" type="video/mp4">
//             <source src="movie.ogg" type="video/ogg">
//            Your browser does not support the video tag.
//         </video>
//       ) : (
//         <img className={styles.img} src={data.src} />
//       )}
//     </div>
//   );
// };

const Commission = ({ comm }) => {
  const img = comm.node.frontmatter.featuredImage.childImageSharp.fluid
  var caption = comm.node.frontmatter.title
  var desc = comm.node.frontmatter.description
  const [toggler, setToggler] = useState(false)
  const allImages = []
  allImages.push({
    src: img.src,
    srcSet: img.srcSet,
    caption: caption,
    desc: desc,
  })
  if (comm.node.frontmatter.otherImages) {
    comm.node.frontmatter.otherImages.forEach(img => {
      const fluid = img.childImageSharp.fluid
      allImages.push({
        src: fluid.src,
        srcSet: fluid.srcSet,
        caption: caption,
        desc: desc,
      })
    })
  }
  const multiImage = allImages.length > 1
  return (
    <>
      <GalleryImage onClick={() => setToggler(true)}>
        <div>
          <Img fluid={img} alt={caption} className="galleryImage" />
          {multiImage ? (
            <span className="multiImage">
              <FontAwesomeIcon icon={faClone} />
            </span>
          ) : null}
          <span className="expandImage">
            <FontAwesomeIcon icon={faExpand} />
          </span>
        </div>
      </GalleryImage>
      <ModalGateway>
        {toggler ? (
          <Modal
            allowFullscreen={true}
            onClose={() => setToggler(false)}
            preventScroll={true}
            closeOnBackdropClick={false}
          >
            <Carousel
              views={allImages}
              showNavigationOnTouchDevice={true}
              components={{
                FooterCaption: CustomFooter,
                Header: CustomHeader,
                NavigationNext: CustomNext,
                NavigationPrev: CustomPrev,
                View: CustomView,
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
const Commissions = ({ size }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { slug: { regex: "/commissions-/" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              otherImages {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              date
            }
          }
        }
      }
    }
  `)
  const grid = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout()
      }
    })
  }, [grid])
  const allCommissions = data.allMarkdownRemark.edges
  return (
    <Article>
      <ArticleHeader title="Commissions" />
      <StackGrid
        gridRef={r => (grid.current = r)}
        columnWidth={size.width <= 768 ? "100%" : "33.33%"}
        monitorImagesLoaded={true}
        duration={0}
        gutterWidth={25}
        gutterHeight={20}
        appearDelay={60}
      >
        {allCommissions.map(comm => (
          <Commission key={comm.node.fields.slug} comm={comm} />
        ))}
      </StackGrid>
    </Article>
  )
}

export default sizeMe()(Commissions)
