import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Commissions from "../components/commissions"

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Commissions" />
      <Commissions />
    </Layout>
  )
}

export default Contact
